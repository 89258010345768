import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';

export const useFavoriteCount = (entryId: number, showFavoriteCount: boolean) => {
  const { apiClient } = useApi();

  const fetchFavoriteCount = async (entryId: number): Promise<number> => {
    const { data } = await apiClient.get<{ count: number }>(`/api/net-auctions/${entryId}/favorite-count`);
    return data.count;
  };

  const { data: favoriteCount } = useQuery({
    queryKey: ['favoriteCount', entryId],
    queryFn: () => fetchFavoriteCount(entryId),
    staleTime: 1000 * 60,
    enabled: showFavoriteCount && !!entryId,
  });

  return favoriteCount ?? 0;
};
