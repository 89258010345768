import { getManufacturer } from './metadata';
import { createEcommerceItem } from './gtm-service';
import { ListEntry } from '../types/ListEntry';
import { CategoryDynamicData } from '../types/Category';
import { priceWithoutVat } from './price';

export function listEntryToEcommerceItem(
  entry: ListEntry,
  categories: CategoryDynamicData[],
  indexInList: number
): Gtag.Item {
  return createEcommerceItem({
    id: String(entry.id),
    name: entry.title,
    company: entry.companyName ?? '',
    brand: getManufacturer(entry.metadata),
    category: entry.categoryName,
    parentCategory: categories.find((category) => category.id === entry.parentCategoryId)?.name,
    location: `${entry.zipCode} ${entry.city}`,
    priceWithoutShippingOrTax: priceWithoutVat(entry.highestBid ?? entry.startPrice).amount,
    index: indexInList,
  });
}
